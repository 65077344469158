<script>
import Datepicker from '@/components/Datepicker'
import { Mixin } from '@/mixins/util.js'
import { Table, TableColumn, Button, Pagination,Row, Col } from 'element-ui'

export default {
  name: 'instock',
  mixins: [Mixin],
  components: {
    Pagination,
    Datepicker,
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-pagination': Pagination,
    'el-row': Row,
    'el-col': Col,
  },
  data() {
    return {
      startDate: '2019-12-01',
      table_81: [],
      table_82: [],
      table_83: [],
      table_84: [],
      table_86: [],
      table_87: [],
      table_305: [],
      colConfigs: [
        { prop: 'idx', label: '#', width: '50' },
        { prop: '0', label: '弊社管理コード', width: '80' },//
        { prop: '1', label: '商品名', width: '250' },//
        { prop: '2', label: '出荷予測週数', width: '90' },//
        { prop: '3', label: '前月末在庫数', width: '80' },//
        { prop: '4', label: '入荷数', width: '70' },//
        { prop: '5', label: '輸入時破損数', width: '70' },//
        { prop: '6', label: '理論出荷数', width: '100' },
        { prop: '7', label: '出荷数', width: '70' },//
        { prop: '18', label: '正常→正常', width: '80' },
        { prop: '8', label: '理論拒収数', width: '100' },
        { prop: '9', label: '拒収数', width: '70' },//
        { prop: '10', label: '受取拒否不良品', width: '80' },//
        { prop: '11', label: '理論返品数', width: '70' },
        { prop: '12', label: '返品数(良品)', width: '80' },//
        { prop: '13', label: '返品数(不良品)', width: '80' },//
        { prop: '14', label: '良品在庫移動', width: '80' },
        { prop: '15', label: '月末理論在庫数', width: '80' },//
        { prop: '16', label: '棚卸し在庫数', width: '70' }//
      ],
      pagesize: 20,
      currentPage_81: 1,
      currentPage_83: 1,
      currentPage_86: 1,
      currentPage_87: 1,
    }
  },
  computed: {
    slice81Data(){
      return this.table_81TotableData
      .slice((this.currentPage_81-1)*this.pagesize,this.currentPage_81*this.pagesize)
    },
    pageTotal_81() {
      return (this.table_81TotableData.length / this.pagesize) * 10
    },
    slice83Data(){
      const firstTwoRow = [this.table_83_edit[0],this.table_83_edit[1]]
      const restRow = this.table_83_edit
      .slice((this.currentPage_83-1)*this.pagesize+2,this.currentPage_83*this.pagesize+2)
      return [...firstTwoRow,...restRow]
    },
    pageTotal_83() {
      return (this.table_83_edit.length / this.pagesize) * 10
    },
    slice86Data(){
      const firstTwoRow = [this.table_86_edit[0],this.table_86_edit[1]]
      const restRow = this.table_86_edit
      .slice((this.currentPage_86-1)*this.pagesize+2,this.currentPage_86*this.pagesize+2)
      return [...firstTwoRow,...restRow]
    },
    pageTotal_86() {
      return (this.table_86_edit.length / this.pagesize) * 10
    },
    slice87Data(){
      return this.table_87_edit
      .slice((this.currentPage_87-1)*this.pagesize,this.currentPage_87*this.pagesize)
    },
    pageTotal_87() {
      return (this.table_87_edit.length / this.pagesize) * 10
    },
    contentData() {
      // const thisYear = new Date().getFullYear()
      // const thisMonth = new Date().getMonth() + 1
      // const selectMonth = this.$children[1].month
      // const selectYear = this.$children[1].year
      const data = this.table_81TotableData.map(item => {
        let d = []
        for (const {prop} of this.colHeader) {
          if(prop!=='idx')
          d.push(item[prop])
        }
        return d
      })
      
      const data2 = this.table_83_edit
        .map(item => {
          const data = Object.entries(item)
          return data.map(t => t[1])
        })
        .map((t, i) => {
          if (i < 2) t.unshift('', '')
          else t.shift()
          return t
        })
      const data3 = this.table_86_edit
        .map(item => {
          const data = Object.entries(item)
          return data.map(t => t[1])
        })
        .map((t, i) => {
          if (i < 2) t.unshift('', '', '', '')
          else t.shift()
          return t
        })
      const data4 = this.table_87_edit
        .map(item => {
          const data = Object.entries(item)
          return data.map(t => t[1])
        })
        .map((t, i) => {
          if (i < 2) t.unshift('', '', '')
          else t.shift()
          return t
        })
      return [data, data2, data3, data4]
    },
    header() {
      const title = this.colHeader.map(item => {
        const temp = Object.entries(item)
        let title
        for (const iterator of temp) {
          if (iterator[0] === 'label') title = iterator[1]
        }
        return title
      })
      const title2 = this.table_83_header.map(
        item => Object.entries(item)[1][1]
      )
      const title3 = this.table_86_header.map(
        item => Object.entries(item)[1][1]
      )
      const title4 = this.table_87_header.map(
        item => Object.entries(item)[1][1]
      )
      title.shift()
      title2.shift()
      title3.shift()
      title4.shift()
      return [title, title2, title3, title4]
    },
    excelData() {
      return [
        [this.header[0], ...this.contentData[0]],
        [this.header[1], ...this.contentData[1]],
        [this.header[2], ...this.contentData[2]],
        [this.header[3], ...this.contentData[3]]
      ]
    },
    colHeader() {
      let temp = this.colConfigs.map(item => {
        let obj = {
          label: item.label,
          prop: item.prop,
          width: item.width
        }
        return obj
      })
      const thisYear = new Date().getFullYear()
      const thisMonth = new Date().getMonth() + 1
      const notShowIndex = [6, 8, 11, 15]
      if (
        thisMonth <= this.$children[1].month &&
        thisYear <= this.$children[1].year
      ) {
        temp.map((item, i) => {
          i >= 3 ? (item.width = '') : ''
        })

        for (const i of notShowIndex) {
          const index = temp.findIndex(d=>d.prop==i)
          temp.splice(index, 1)
        }
        return temp
      }
      return this.colConfigs
    },
    table_81_edit() {
      if (this.table_81.length && this.table_84.length) {
        let temp = this.table_81.map(items =>
          items.map(item => {
            if (!isNaN(item * 1)) item = item * 1
            else if (item === `N/A`) item = '-'
            return item
          })
        )
        let temp1 = temp.map(item => {
          item.splice(13, 0, 0)
          let temp
          if (isNaN(item[2] * 1)) temp = 0
          else temp = item[2] * 1
          //temp 月末在庫數 3 入荷 7 理論拒收 11 返品(良品)
          //4 破損數 5 理論出荷 13 良品在庫移動
          const total =
            temp +
            item[3] * 1 +
            item[7] * 1 +
            item[11] * 1 -
            item[4] * 1 -
            item[5] * 1 -
            item[13] * 1

          item.splice(14, 0, total)
          // console.log(`a${item[0]}`, item)
          return item
        })
        return temp1.map((item, i) => {
          const item_305 = this.table_305_edit[i] === undefined ?0:this.table_305_edit[i]
          item.splice(2, 0, this.table_84[i])
          item.splice(18, 0, item_305)
          return item
        })
      }
      return []
    },
    table_81TotableData() {
      return this.table_81_edit.map((items, index) => {
        if (this.table_83_edit.length > 0) {
          items[14] = this.table_83_edit[0][items[0]]
          items[15] -= items[14]
        }
        let temp = items.map(item => {
          if (item instanceof Array) item = item[0]
          if (!isNaN(item * 1)) return (item * 1)//.toLocaleString()

          return item
        })

        let temp1 = { ...temp }
        temp1['idx'] = index + 1
        return temp1
      })
    },
    table_83_header() {
      let col = [
        { prop: 'index', label: '#', w: 40 },
        { prop: 'date', label: '日付', w: 100 },
        { prop: 'title', label: '内容', w: 550 },
        { prop: 'quality', label: '良/不良', w: 70 }
      ]
      if (this.table_82.length > 0) {
        for (const title of this.table_82[0]) {
          col.push({
            prop: title,
            label: this.Mosaic(title, sessionStorage.getItem('session_id'))
          })
        }
        return col
      }
      return []
    },
    table83NotZeroCol(){
      if(this.table_83_edit.length===0) return []
      const keys = Object.keys(this.table_83_edit[0])
      const cols = [
        { prop: 'index', label: '#', w: 40 },
        { prop: 'date', label: '日付', w: 100 },
        { prop: 'title', label: '内容', w: 550 },
        { prop: 'quality', label: '良/不良', w: 70 }
      ]
      for (const key of keys) {
        if(key!=='quality'){
          if(this.table_83_edit[0][key]!='' || this.table_83_edit[1][key]!='')
            cols.push({prop:key,label:this.Mosaic(key, sessionStorage.getItem('session_id'))})
        }
      }
      return cols
    },
    table_83_edit() {
      if (this.table_83.length > 0) {
        let good = { quality: '良品' },
          bad = { quality: '不良品' }
        let t = this.table_83.map((item, index) => {
          let obj = { index: index + 1 },
            i = 0,
            quality = null

          for (const header of this.table_83_header) {
            if (quality === '良品') {
              if (good[header.prop] === undefined) good[header.prop] = 0
              good[header.prop] += item[i] * 1
            } else if (quality === '不良品') {
              if (bad[header.prop] === undefined) bad[header.prop] = 0
              bad[header.prop] += item[i] * 1
            }
            if (header.prop === 'quality') quality = item[i]
            if (header.prop !== 'index') {
              if(i>2) obj[header.prop] = item[i++]
              else{
                obj[header.prop] = item[i++]
              }
            }
          }
          return obj
        })
        t = t
          .sort((a, b) => {
            return a.date > b.date ? 1 : -1
          })
          .map((item, index) => {
            item.index = index + 1
            return item
          })
        t.unshift(bad)
        t.unshift(good)
        // return t
        return t.map(d => {
          for (let header of this.table_82[0]) {
            if (d[header] !== '') d[header] = d[header] * 1
          }
          return d
        })
      }
      return []
    },
    table_86_header() {
      let col = [
        { prop: 'index', label: '#', w: 40 },
        { prop: 'date', label: '日付', w: 100 },
        { prop: 'distributor_id', label: '卸先ID', w: 120 },
        { prop: 'order_id', label: '受注NO.', w: 200 },
        { prop: 'branch_id', label: '分店', w: 230 },
        { prop: 'quality', label: '良/不良', w: 70 }
      ]
      if (this.table_82.length > 0) {
        for (const title of this.table_82[0]) {
          col.push({
            prop: title,
            label: this.Mosaic(title, sessionStorage.getItem('session_id'))
          })
        }
        return col
      }
      return []
    },
    table86NotZeroCol(){
      if(this.table_86_edit.length===0) return []
      const keys = Object.keys(this.table_86_edit[1])
      const cols = [
        { prop: 'index', label: '#', w: 40 },
        { prop: 'date', label: '日付', w: 100 },
        { prop: 'distributor_id', label: '卸先ID', w: 120 },
        { prop: 'order_id', label: '受注NO.', w: 200 },
        { prop: 'branch_id', label: '分店', w: 230 },
        { prop: 'quality', label: '良/不良', w: 70 }
      ]
      for (const key of keys) {
        if(key!=='quality'){
          if(this.table_86_edit[1][key]!=0 && (this.table_86_edit[1][key]!=0 || this.table_86_edit[1][key]!=undefined))
            cols.push({prop:key,label:this.Mosaic(key, sessionStorage.getItem('session_id'))})
        }
      }
      return cols
    },
    table_86_edit() {
      if (this.table_86.length > 0) {
        let good = { quality: '良品' },
          bad = { quality: '不良品' }
        let t = this.table_86.map((item, index) => {
          let obj = { index: index + 1 },
            i = 0,
            quality = null

          for (const header of this.table_86_header) {
            if (quality === '良品') {
              if (good[header.prop] === undefined) good[header.prop] = 0
              good[header.prop] += item[i] * 1
            } else if (quality === '不良品') {
              if (bad[header.prop] === undefined) bad[header.prop] = 0
              bad[header.prop] += item[i] * 1
            }
            if (header.prop === 'quality') quality = item[i]
            if (header.prop !== 'index') obj[header.prop] = item[i++]
          }
          return obj
        })
        t = t
          .sort((a, b) => {
            if (a.date > b.date) return 1
            if (a.date < b.date) return -1
            if (a.distributor_id > b.distributor_id) return 1
            if (a.distributor_id < b.distributor_id) return -1
            if (a.order_id > b.order_id) return 1
            if (a.order_id < b.order_id) return -1
            return 0
          })
          .map((item, index) => {
            item.index = index + 1
            return item
          })
        t.unshift(bad)
        t.unshift(good)

        // return t.map(d => {
        //   for (let header of this.table_82[0]) {
        //     if (d[header] !== '') d[header] = d[header] * 1
        //   }
        //   return d
        // })
        return t
      }
      return []
    },
    table_87_header() {
      let col = [
        { prop: 'index', label: '#', w: 40 },
        { prop: 'date', label: '日付', w: 100 },
        { prop: 'source', label: '販路', w: 200 },
        { prop: 'title', label: '訂單編號', w: 350 },
        { prop: 'quality', label: '良/不良', w: 70 }
      ]
      if (this.table_82.length > 0) {
        for (const title of this.table_82[0]) {
          col.push({
            prop: title,
            label: this.Mosaic(title, sessionStorage.getItem('session_id'))
          })
        }
        return col
      }
      return []
    },
    table87NotZeroCol(){
      if(this.table_87_edit.length===0) return []
      const keys = Object.keys(this.table_87_edit[0])
      const cols = [
        { prop: 'index', label: '#', w: 50 },
        { prop: 'date', label: '日付', w: 100 },
        { prop: 'source', label: '販路', w: 200 },
        { prop: 'title', label: '訂單編號', w: 350 },
        { prop: 'quality', label: '良/不良', w: 70 }
      ]
      for (const key of keys) {
        if(key!=='quality'){
          if(this.table_87_edit[0][key]!='' || this.table_87_edit[1][key]!='')
            cols.push({prop:key,label:this.Mosaic(key, sessionStorage.getItem('session_id'))})
        }
      }
      return cols
    },
    table_87_edit() {
      if (this.table_87.length > 0) {
        let good = { quality: '良品' },
          bad = { quality: '不良品' }
        let t = this.table_87.map((item, index) => {
          let obj = { index: index + 1 },
            i = 0,
            quality = null

          for (const header of this.table_87_header) {
            if (quality === '良品') {
              if (good[header.prop] === undefined) good[header.prop] = 0
              good[header.prop] += item[i] * 1
            } else if (quality === '不良品') {
              if (bad[header.prop] === undefined) bad[header.prop] = 0
              bad[header.prop] += item[i] * 1
            }
            if (header.prop === 'quality') quality = item[i]
            if (header.prop !== 'index') obj[header.prop] = item[i++]
          }
          return obj
        })
        t = t
          .sort((a, b) => {
            if (a.date > b.date) return 1
            if (a.date < b.date) return -1
            if (a.source > b.source) return 1
            if (a.source < b.source) return -1
            return 0
          })
          .map((item, index) => {
            item.index = index + 1
            return item
          })
        t.unshift(bad)
        t.unshift(good)
        return t.map(d => {
          for (let header of this.table_82[0]) {
            if (d[header] && d[header] !== '') d[header] = d[header] * 1
          }
          return d
        })
      }
      return []
    },
    table_305_edit(){
      return this.table_305
        .filter(d=>d[2]==='良品')
        .reduce((a,c)=>{
          for (let index = 3; index < c.length; index++) {
            if(a[index-3]===undefined) a[index-3] = 0
            a[index-3]+=c[index]*1
          }
          return a
        },[])
    },
  },
  methods: {
    current_81_change(currentPage){
      this.currentPage_81 = currentPage
    },
    current_83_change(currentPage){
      this.currentPage_83 = currentPage
    },
    current_86_change(currentPage){
      this.currentPage_86 = currentPage
    },
    current_87_change(currentPage){
      this.currentPage_87 = currentPage
    },
    dowexcel() {
      this.dwnExcel(
        this.excelData,
        '在庫',
        ['商品', '在庫移動', '店鋪移動', '廠配移動'],
        []
      )
    },
    getChartDataWithTimeOut(y, m) {
      const result = this.fetchWithTimeout(y, m, '81,82,83,84,86,87,305')
      this.$eventBus.$emit('loadingHandler', true)
      result
        .then(res => res.text())
        .then(rep => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          this.$eventBus.$emit('loadingHandler', false)
          // console.log(rep)
          if (rep.errno !== '0') {
            this.$eventBus.$emit('logOutHandler', true)
            this.ToLoginPage()
          } else {
            const keys = Object.keys(rep).filter(item => !isNaN(item))
            keys.forEach(key => (this[`table_${key}`] = rep[key]))
            this.table_81 = this.table_81.map(item => {
              item[0] = this.Mosaic(
                item[0],
                sessionStorage.getItem('session_id')
              )
              item[1] = this.Mosaic(
                item[1],
                sessionStorage.getItem('session_id')
              )
              return item
            })
            this.table_83 = this.table_83.map(item => {
              item[1] = this.Mosaic(
                item[1],
                sessionStorage.getItem('session_id')
              )
              return item
            })
            this.table_86 = this.table_86.map(item => {
              item[2] = item[2].split('_')[0]
              
              item[3] = this.Mosaic(
                item[3],
                sessionStorage.getItem('session_id')
              )
              return item
            })
          }
        })
        .catch(() => {
          this.$eventBus.$emit('loadingHandler', false)
          this.$eventBus.$emit('serverDownHandler', true)
          this.ToLoginPage()
        })
    },
    ToLoginPage() {
      this.timer = setTimeout(() => {
        this.$router.push({
          name: 'login',
          params: { t: '123' }
        })
      }, 2500)
    },
    rowClass({ rowIndex }) {
      // console.log(rowIndex)
      if (rowIndex < 2) return 'success-row'
      return ''
    },
    tableCellAlign({ columnIndex }) {
      if (columnIndex <= 1) return 'text-align:center;'
      if (columnIndex > 2) return 'text-align:right;'
    },
    stockCellAlign({ row, column, columnIndex }) {
      let count = null,
        style = ''
      if (column.property === '2') {
        if (typeof row[column.property] === 'object')
          count = row[column.property][0].replace(/,/g, '')
        else count = row[column.property].toString().replace(/,/g, '')
        if (parseInt(count) < 5)
          style = 'color:red;font-weight:900;font-size: 20px;'
      }
      if (columnIndex <= 1) return `${style}text-align:center;`
      if (columnIndex > 2) return `${style}text-align:right;`
    }
  },
  created() {
    // this.getData(new Date().getFullYear(), new Date().getMonth() + 1)
    this.getChartDataWithTimeOut(
      new Date().getFullYear(),
      new Date().getMonth() + 1
    )
    // this.getChartDataWithTimeOut(2019, 12)
  },
  mounted() {
    const menu = document.querySelector('.menu')
    const ham = document.querySelector('.ham')
    menu.style.left = '-320px'
    ham.style.display = 'block'
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
